import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdWifiCalling3 } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { TbAlignBoxLeftMiddle } from "react-icons/tb";
import { TbRollerSkating } from "react-icons/tb";

const Navbar = () => {
  const [toggleColor, setToggleColor] = useState();
  return (
    <>
      <div className="bg-[#fff] bg-cover w-[240px] min-h-screen font-['Roboto']">
        <div className="flex flex-row">
          <div>
            <img
              src="/logo1.svg"
              alt="images"
              className="ml-[24px] mt-[31px] w-[118px]"
            />
          </div>
        </div>
        <ul className="flex flex-col">
          <li className="flex flex-row items-center">
            <Link
              to="/Home"
              onClick={() => setToggleColor("Home")}
              className={`${
                toggleColor === "Home" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <AiOutlineHome className="w-[30px] h-[30px] text-[#85A3B8] ml-[1rem]" />
              <h1
                className=" lg:ml-[10px] text-[#85A3B8] 2xl:text-lg !text-sm font-medium"
                style={{ lineHeight: "15px" }}
              >
                Home Page
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[1rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/Program1"
              onClick={() => setToggleColor("Programs")}
              className={`${
                toggleColor === "Programs" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <TbAlignBoxLeftMiddle className="w-[30px] h-[30px] text-[#85A3B8]  ml-[1rem]" />
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Programs
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[1.2rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/Gallery"
              onClick={() => setToggleColor("Gallery")}
              className={`${
                toggleColor === "Gallery" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <TfiGallery className="w-[30px] h-[30px] text-[#85A3B8] ml-[1rem]" />
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Gallery
              </h1>
              <BiChevronDown className="w-[30px] h-[30px] text-[#85A3B8] lg:mx-[2.2rem]" />
            </Link>
          </li>

          <li className="flex flex-row items-center">
            <Link
              to="/Career"
              onClick={() => setToggleColor("Career")}
              className={`${
                toggleColor === "Career" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <TbRollerSkating className="w-[30px] h-[30px] text-[#85A3B8] ml-[1rem]" />{" "}
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Career
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#85A3B8] lg:mx-[2.3rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
          <li className="flex flex-row items-center">
            <Link
              to="/About"
              onClick={() => setToggleColor("About")}
              className={`${
                toggleColor === "About" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <AiFillInfoCircle className="w-[30px] h-[30px] ml-[1rem] text-[#85A3B8]" />
              <h1 className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm font-medium">
                About Us
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[1rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>

          <li className="flex flex-row items-center">
            <Link
              to="/Contact1"
              onClick={() => setToggleColor("ContactUsPage")}
              className={`${
                toggleColor === "ContactUsPage" ? "bg-[#1572ED]" : ""
              } flex items-center h-[48px] mt-10`}
            >
              <MdWifiCalling3 className="w-[30px] h-[30px] text-[#85A3B8] ml-[1rem]" />{" "}
              <h1
                className="ml-[13px] text-[#85A3B8] 2xl:text-lg !text-sm  font-medium"
                style={{ lineHeight: "15px" }}
              >
                Contact Us
              </h1>
              <BiChevronDown
                className="w-[30px] h-[30px] text-[#fff] lg:mx-[0.6rem]"
                style={{ color: "#85A3B8" }}
              />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
