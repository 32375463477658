import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import PublishButton from "../publish/publishButton";
import { SaveChangesPopup } from "../../component/Home/savePopup";
import SaveButton from "../Buttons/saveButton";
import { toast } from "react-toastify";

const Career2 = () => {
  const [toggleSwitch, setToggleSwitch] = React.useState({});
  const [slidesData, setSlidesData] = useState([
    {
      id: 1,
      Link: "",
      Photos: "",
      Heading: "",
      Published: false,
    },
  ]);
  const getLinkData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcareerData`);
      const fetchedData = response.data.data;
      const initialSwitchState = {};
      fetchedData.forEach((slide) => {
        initialSwitchState[slide.id] = slide.Published;
      });
      setSlidesData(response.data.data);
      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        setSlidesData(data);
      } else {
        const defaultData = [{ id: "1", Photos: "" }];
        setSlidesData(defaultData);
      }
      setToggleSwitch(initialSwitchState);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getLinkData();
  }, []);

  const handleAddRow = () => {
    setSlidesData((prevSlidesData) => [
      ...prevSlidesData,
      {
        id: prevSlidesData.length + 1,
        Link: "",
        Photos: "",
        Heading: "",
        Published: false,
      },
    ]);
  };
  const handleHeadingChange = (slideId, value) => {
    setSlidesData((prevSlidesData) =>
      prevSlidesData.map((slide) =>
        slide.id === slideId ? { ...slide, Heading: value } : slide
      )
    );
  };
  const handleLinkChange = (slideId, value) => {
    setSlidesData((prevSlidesData) =>
      prevSlidesData.map((slide) =>
        slide.id === slideId ? { ...slide, Link: value } : slide
      )
    );
  };

  const saveFeePrograms = async (slideId) => {
    const slideData = slidesData.find((slide) => slide.id === slideId);
    try {
      const config = {
        method: "POST",
        mode: "cors",
        url: `${API_BASE_URL}/careerData`,
        headers: {
          "Content-Type": "application/json",
        },
        data: slideData,
      };
      const response = await axios(config);
      if (response?.data?.status) {
        toast.success("Data created successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const handleSwitch = async (slideId) => {
    //e.stopPropagation();
    // const slideData = slidesData.find((slide) => slide.id === slideId);
    const isPublished = !toggleSwitch[slideId];
    setToggleSwitch((prev) => ({
      ...prev,
      [slideId]: isPublished,
    }));
    try {
      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updateCareerData/${slideId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { id: slideId, Published: isPublished },
      };
      const response = await axios(config);
      if (response?.data?.status) {
        toast.success("Data updated successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const [togglePop, setTogglePop] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState(null);

  // Open popup and set active slide
  const openPopUp = (slideId) => {
    setActiveSlideId(slideId); // Set the slide ID that is being edited
    setTogglePop(true);
  };

  // Reset active slide and close popup
  const closePopUp = () => {
    setActiveSlideId(null); // Reset the active slide ID
    setTogglePop(false);
  };

  // Handle the actual switch action when "Save" is clicked
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    if (activeSlideId !== null) {
      await handleSwitch(activeSlideId);
    }
  };
  const handleDelete = async (id) => {
    console.log("Deleting ID:", id);
    try {
      const response = await axios.delete(`${API_BASE_URL}/deletecareerId/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const updatedSlidesData = slidesData.filter((slide) => slide.id !== id);
        setSlidesData(updatedSlidesData);

        if (expanded === `panel${id}`) {
          setExpanded(null);
        }

        toast.success("Data deleted successfully");
      } else {
        toast.error("Failed to delete the Data");
      }
    } catch (error) {
      console.error("Error deleting Data:", error);
      toast.error("An error occurred while deleting the Data");
    }
  };
  const handlePhotosChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSlidesData((prev) =>
          prev.map((slide) =>
            slide.id === id ? { ...slide, Photos: reader.result } : slide
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick1 = (id) => {
    document.getElementById(`fileInput1+${id}`).click();
  };
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="flex flex-col">
      {slidesData.map((slide) => (
        <div
          className="flex lg:ml-[40px] 2xl:w-[900px] 2xl:ml-[90px] lg:w-[600px] my-2 rounded-3xl"
          key={slide.id}
        >
          <SaveChangesPopup
            open={togglePop}
            onSave={onSaveChanges}
            onClose={closePopUp}
          />
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              className="text-base  text-opacity-60 font-base text-[#2C2C2C]"
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${slide.id}a-content`}
              id={`panel${slide.id}a-header`}
            >
              <Typography className="text-opacity-60 text-[#1A2338]">{`Slide ${slide.id}`}</Typography>
              <span className="publish-button-wrapper">
                <PublishButton
                  isPublished={toggleSwitch[slide.id]}
                  openPopUp={() => openPopUp(slide.id)}
                  slideId={slide.id}
                  className="flex justify-self-end"
                />
              </span>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col">
              <div className="flex flex-row">
                <div className="flex flex-col font-['Roboto']">
                  <label className="ml-[30px] 2xl:[14px]  font-semibold text-[#1A233899]">
                    Heading
                  </label>
                  <input
                    type="text"
                    className="mt-[4px] text-[12px] ml-[30px]  border border-1 border-[#0000003B] px-2 py-2 2xl:w-[460px] 2xl:h-[56px] lg:w-[350px] lg:h-[40px]  rounded"
                    value={slide.Heading}
                    placeholder="Type heading here ....."
                    name="Heading"
                    onChange={(e) =>
                      handleHeadingChange(slide?.id, e.target.value)
                    }
                  />
                  <label className="ml-[30px] mt-[27px] 2xl:text-[14px] font-semibold text-[#1A233899]">
                    Link
                  </label>
                  <input
                    className="mt-[4px] text-[12px] ml-[30px]  border border-1 border-[#0000003B] px-2 py-2 2xl:w-[460px] 2xl:h-[56px] lg:w-[350px] lg:h-[40px]  rounded"
                    value={slide.Link}
                    placeholder="Type Link ...."
                    name="chapterLink"
                    onChange={(e) =>
                      handleLinkChange(slide?.id, e.target.value)
                    }
                  />
                </div>
                <div className="space-y-4">
                  <div
                    className="2xl:ml-[32px] lg:ml-[33px] flex flex-col items-center justify-center 2xl:w-[378px] lg:w-[250px] 2xl:h-[200px] lg:h-[150px] rounded bg-[#C2C2C28F]"
                    onDoubleClick={() => handleClick1(slide.id)}
                  >
                    <input
                      type="file"
                      id={`fileInput1+${slide.id}`}
                      style={{ display: "none" }}
                      onChange={(e) => handlePhotosChange(slide?.id, e)}
                      accept="image/*"
                    />
                    {slide?.Photos ? (
                      <img
                        src={slide?.Photos}
                        alt="uploaded"
                        className="object-cover w-full h-full rounded"
                      />
                    ) : (
                      <img src="/Vector.png" alt="upload-icon" className="" />
                    )}
                    {!slide?.Photos && (
                      <>
                        <p className="text-sm text-gray-500 mt-[11px]">
                          "Drag & Drop" or <br /> "Double click to upload image"
                        </p>
                        <p className="flex self-end lg:mt-[2rem]  text-xs text-gray-400 mr-[2rem]">
                          SVG, PNG, JPG or GIF (max. 5MB)
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex self-end">
                <SaveButton
                  onSave={() => saveFeePrograms(slide.id)}
                  onCancel={() => handleDelete(slide.id)}
                  />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <button
        className="flex self-center font-['Roboto'] border-2 border-gray-500 rounded-lg px-6 py-3"
        onClick={handleAddRow}
      >
        ADD ROW +
      </button>
    </div>
  );
};

export default Career2;
