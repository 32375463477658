import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../src/config";
import PublishButton from "../publish/publishButton";
import { SaveChangesPopup } from "../../component/Home/savePopup";
import { toast } from "react-toastify";
import SaveButton from "../Buttons/saveButton";

const Career1 = () => {
  const [selectedImage, setSelectedImage] = useState([
    {
      id: "0",
      image: "",
    },
    {
      id: "1",
      image: "",
    },
  ]);
  const [slideId, setSlideId] = useState(1);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const handleImageChange = (e, id) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, image: reader.result } : item
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  async function saveHomeData() {
    try {
      const token = sessionStorage.getItem("token");

      const payload = {
        id: 1,
        Photos: selectedImage,
        Published: toggleSwitch,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/careerImageData`,
        payload,
        config
      );
      if (response.data.status) {
        toast.success("Data created successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const getHomeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcareerImageData`);
      setSlideId(response.data.data?.id || 1);
      const photos = response.data.data?.Photos;
      if (Array.isArray(photos) && photos.length > 0) {
        setSelectedImage(photos);
      } else {
        const defaultPhotos = [
          { id: "0", image: "" },
          { id: "1", image: "" },
        ];
        setSelectedImage(defaultPhotos);
      }
      setToggleSwitch(response?.data?.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeData();
  }, []);
  function onCancel() {

  }
  async function handleSwitch() {
    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updatecareerImageData/${1}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        data: { Published: toggleSwitch },
      };
      await axios(config);
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const [togglePop, setTogglePop] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState(null);
  const openPopUp = (slideId) => {
    setActiveSlideId(slideId);
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setActiveSlideId(null);
    setTogglePop(false);
  };
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    await handleSwitch(activeSlideId);
  };

  return (
    <>
      <div className="flex flex-col 2xl:ml-[90px] ml-[20px] lg:ml-[40px]  2xl:w-[890px] h-auto lg:w-[700px] rounded-3xl  bg-[#FFFFFF]">
        <div className="flex self-end mt-3">
          <PublishButton
            isPublished={toggleSwitch}
            openPopUp={() => openPopUp(slideId)}
            slideId={slideId}
          />
        </div>
        <div className="flex flex-col">
          <SaveChangesPopup
            open={togglePop}
            onSave={onSaveChanges}
            onClose={closePopUp}
          />
          <div className="flex flex-row">
            {selectedImage?.map((imageObj, index) => {
              const inputId = `fileInput-${imageObj.id}`;
              return (
                <div key={index} className="flex flex-col font-['Roboto'] my-4">
                  <h1 className="2xl:ml-[32px] lg:ml-[32px] pt-[26px] font-bold text-[14px] text-[#1A233899]">
                    {`Image ${index + 1}`}
                  </h1>
                  <label htmlFor={inputId} className="cursor-pointer">
                    <div
                      className="2xl:w-[378px] lg:w-[250px] 2xl:h-[200px] lg:h-[200px] 2xl:ml-[32px] lg:ml-[33px] flex flex-col items-center justify-center rounded bg-[#C2C2C28F]"
                      onDoubleClick={handleClick}
                      onDrop={(e) => handleImageChange(e, imageObj.id)}
                    >
                      {/* Make the input's ID match the label's `htmlFor` */}
                      <input
                        type="file"
                        id={inputId}
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, imageObj.id)}
                        accept="image/*"
                      />
                      {imageObj.image ? (
                        <img
                          src={imageObj.image}
                          alt={`Upload ${index + 1}`}
                          className="object-cover w-full h-full rounded"
                        />
                      ) : (
                        <img src="/Vector.png" alt="upload-icon" className="" />
                      )}
                    </div>
                  </label>
                  <p className="flex self-end lg:mt-[4px]  text-xs text-gray-400">
                    SVG, PNG, JPG or GIF (max. 5MB)
                  </p>
                </div>
              );
            })}
          </div>
          <div className="flex self-end mx-4">
            <SaveButton onSave={saveHomeData} onCancel={onCancel} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Career1;
