import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config";
import { SaveChangesPopup } from "../Home/savePopup";
import axios from "axios";
import { toast } from "react-toastify";
import PublishButton from "../publish/publishButton";
import ImageUploadContact from "./ImageUploadContact";
import ContactEmail from "./contactEmail";

const Contact1 = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  async function handleSave() {}
  function handleClose() {
    setShowPopUp(false);
  }
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [togglePop, setTogglePop] = useState(false);
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const inputAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const openPopUp = (slideId) => {
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setTogglePop(false);
  };
  async function handleSwitch(e, activeSlideId) {
    try {
      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updatecontactpageData/${1}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { Published: toggleSwitch },
      };
      const response = await axios(config);
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Published Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    await handleSwitch();
  };
  const getAboutData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcontactpageData`);
      setImage(response.data.data?.Photo);
      setAddress(response.data.data?.Address);
      setToggleSwitch(response.data.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <div className="bg-[#F1F5F9] bg-cover">
      <div className="flex flex-row font-['Roboto']">
        <div className="flex flex-col">
          <h1
            className="lg:ml-[40px] lg:mt-[24px] 2xl:ml-[90px] 2xl:mt-[48px] font-['Roboto'] font-semibold lg:text-[40px] 2xl:text-4xl text-[#1A2338]"
            style={{ lineHeight: "78px" }}
          >
            Contact us
          </h1>
          <p className="my-2 font-bold lg:text-[24px] 2xl:text-xl text-sm 2xl:ml-[90px] lg:ml-[40px] text-[#1A2338B2] ">
            Add address & image
          </p>
        </div>
      </div>
      <div className="bg-[#F1F5F9] bg-cover flex flex-row">
        <div className="font-['Roboto'] flex flex-col  rounded-3xl 2xl:mt-[34px] lg:mt-[24px] bg-[#FFFFFF] w-[40rem] h-[35rem] 2xl:ml-[90px] lg:ml-[50px]">
          <div className="ml-auto">
            <PublishButton
              isPublished={toggleSwitch}
              openPopUp={() => openPopUp(1)}
              slideId={1}
            />
          </div>
          <label className="ml-[30px]  2xl:[14px]  font-semibold text-[#1A233899]">
            Add Address
          </label>
          <input
            type="text"
            className="mt-[4px] text-[12px] ml-[30px]  border border-1 border-[#0000003B] px-2 py-2 2xl:w-[460px] 2xl:h-[56px] lg:w-[350px] lg:h-[40px]  rounded"
            value={address}
            placeholder="Type Address here ....."
            name="Address"
            onChange={(e) => inputAddressChange(e)}
          />
          <div className="flex flex-row items-center">
            <h1 className="mb-[13px] ml-[30px] pt-[26px] font-bold text-sm text-left text-[#1A233899]">
              Image1
            </h1>
          </div>
          <SaveChangesPopup
            open={togglePop}
            onSave={onSaveChanges}
            onClose={closePopUp}
          />
          <ImageUploadContact
            selectedImage={image}
            address={address}
            setImage={setImage}
            setToggleSwitch={setToggleSwitch}
            toggleSwitch={toggleSwitch}
            slideId={1}
          />
        </div>
        <ContactEmail />
      </div>
      <SaveChangesPopup
        onSave={handleSave}
        open={showPopUp}
        onClose={handleClose}
      />
    </div>
  );
};

export default Contact1;
