import React, { useEffect, useState } from "react";
import { MdAdd, MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import SaveButton from "../Buttons/saveButton";
import PublishButton from "../publish/publishButton";
import { API_BASE_URL } from "../../config";
import { SaveChangesPopup } from "../../component/Home/savePopup";
import axios from "axios";

const Gallery = () => {
  const [imageList, setImageList] = useState([null, null, null]);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [togglePop, setTogglePop] = useState(false);
  const handleImageChange = (index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageList = [...imageList];
        newImageList[index] = reader.result;
        setImageList(newImageList);
      };
      reader.readAsDataURL(file);
    }
  };

  const addImageBox = () => {
    setImageList([...imageList, null]);
  };

  const removeImageBox = (index) => {
    const newImageList = [...imageList];
    newImageList.splice(index, 1);
    setImageList(newImageList);
  };

  const handleCancel = () => {
    setImageList(null);
  };

  const openPopUp = (slideId) => {
    setActiveSlideId(slideId);
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setActiveSlideId(null);
    setTogglePop(false);
  };
  async function handleSwitch(e, activeSlideId) {
    // e.stopPropagation();
    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updategalleryData/${1}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        data: { Published: toggleSwitch },
      };
      const response = await axios(config);
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Published Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  async function saveHomeData() {
    try {
      const token = sessionStorage.getItem("token");

      const payload = {
        id: 1,
        Photos: imageList,
        Published: toggleSwitch,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${API_BASE_URL}/creategalleryData`,
        payload,
        config
      );
      if (response.data.status) {
        toast.success("Data created successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const [activeSlideId, setActiveSlideId] = useState(null);
  const getHomeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getgalleryData`);
      setToggleSwitch(response?.data?.data?.Published);
      const photos = response.data.data?.Photos;
      if (Array.isArray(photos) && photos.length > 0) {
        setImageList(photos);
      } else {
        const defaultPhotos = ["", "", ""];
        setImageList(defaultPhotos);
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeData();
  }, []);
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    if (activeSlideId !== null) {
      await handleSwitch(activeSlideId);
    }
  };
  return (
    <>
      <div className="bg-[#F1F5F9] bg-cover min-h-screen">
        <SaveChangesPopup
          open={togglePop}
          onSave={onSaveChanges}
          onClose={closePopUp}
        />
        <h1
          className="lg:ml-[40px]  lg:mt-[24px] 2xl:ml-[90px] 2xl:mt-[48px] font-['Roboto'] font-semibold lg:text-[40px] 2xl:text-4xl text-[#1A2338]"
          style={{ lineHeight: "78px" }}
        >
          Gallery
        </h1>
        <div className="font-['Roboto'] flex flex-col  rounded-3xl 2xl:mt-[34px] lg:mt-[24px] bg-[#FFFFFF] w-[45rem] h-auto 2xl:ml-[90px] lg:ml-[50px]">
          <div className="ml-auto">
            {" "}
            <PublishButton
              isPublished={toggleSwitch}
              openPopUp={() => openPopUp(1)}
              slideId={1}
            />
          </div>
          <p className="my-2 font-bold lg:text-[24px] mt-[1rem] 2xl:text-xl text-sm  lg:ml-[40px] text-[#1A2338B2] opacity-70 ">
            Image
          </p>
          <p className="my-2 font-bold   text-xs lg:ml-[40px] text-[#1A2338B2] opacity-70 ">
            Drag & Drop or Double click to upload image.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {imageList?.map((imageSrc, index) => (
              <div className="flex flex-col">
                <div
                  key={index}
                  onClick={() =>
                    document.getElementById(`fileInput-${index}`).click()
                  }
                  onChange={handleImageChange(index)}
                  className="flex flex-col justify-center p-2 text-center border-2 border-gray-300 border-dashed rounded cursor-pointer w-36 h-36"
                  style={{ background: "rgba(194, 194, 194, 0.56)" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id={`fileInput-${index}`}
                  />
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      alt={`uploaded-${index}`}
                      className="object-cover w-full h-full rounded"
                    />
                  ) : (
                    <MdAdd className="flex self-center mx-auto text-gray-500 cursor-pointer" />
                  )}
                </div>
                {imageSrc && (
                  <MdCancel
                    size={24}
                    className="text-red-500 cursor-pointer"
                    onClick={() => removeImageBox(index)}
                  />
                )}
              </div>
            ))}
            <div
              className="flex p-2 text-center border-2 border-gray-300 border-dashed rounded cursor-pointer w-36 h-36"
              onClick={addImageBox}
            >
              <MdAdd className="flex self-center mx-auto text-gray-500" />
            </div>
          </div>
          <div className="lg:mx-[30rem] ">
            <SaveButton onCancel={handleCancel} onSave={saveHomeData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
